// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/hero-pattern.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fallback[data-v-383c9524],img[data-v-383c9524]{background-color:var(--ha-color-iris);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;display:block;height:100%;width:100%}img[data-v-383c9524]{color:var(--ha-color-air);-o-object-fit:contain;object-fit:contain}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
