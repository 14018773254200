export default {
  methods: {
    resizedSrc(src, size) {
      const separator = src && src.includes('?') ? '&' : '?'
      const resize = `resize=fill:${size.width}:${size.height}`

      return src + separator + resize
    }
  }
}
