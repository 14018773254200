<template>
  <img v-if="isBannerValid" :src="bannerPath" :alt="`Bannière ${form.title}`" loading="lazy" />
  <div v-else class="fallback"><!-- Used to only display background image --></div>
</template>

<script>
import { functions } from '@ha/helpers'
import withImageProxy from '@/mixins/withImageProxy'

export default {
  name: 'CampaignBanner',
  mixins: [withImageProxy],
  props: {
    showVignette: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    form() {
      // "OR" is used to fallback inaccessible enrichedForm properties on _step by original formGetter.
      return (
        this.$store.getters['forms/getEnrichedForm'](this.metadata) ||
        this.$store.getters['forms/getForm'](this.metadata)
      )
    },
    formType() {
      return this.form.formType?.toLowerCase()
    },
    vignetteUrl() {
      return this.form.logo?.publicUrl || ''
    },
    isBannerValid() {
      return !this.showVignette || (this.showVignette && this.vignetteUrl.length)
    },
    bannerPath() {
      if (this.showVignette) {
        return this.vignetteUrl
      }

      return functions.getFormBanner(this.form, { width: 1920, height: 250 })
    }
  }
}
</script>

<style lang="scss" scoped>
img,
.fallback {
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--ha-color-iris);
  background-image: url('@/assets/images/hero-pattern.svg');
  background-position: center;
}

img {
  object-fit: contain;
  color: var(--ha-color-air); // used to read alt text.
}
</style>
